@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Electrolize&family=Major+Mono+Display&family=Megrim&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&family=Turret+Road:wght@200;300;400;500;700;800&display=swap');

body {
  background-color: #021621;

}
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom{
  position: absolute;
  left:0px;
  top:5px;
  width: 24px;
  height: 2px;
  background: black;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle{
  transform: translateY(7px);
}

.hamburger-top{
  transform: translateY(14px);
}

.open{
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top{
  transform: rotate(45deg) translateY(6px) translate(6px);
}
.open .hamburger-middle{
  display: none;
}
.open .hamburger-bottom{
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.Titles{
  text-shadow: 3px 0px 7px #56DBC3;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ee3472;
  border-radius: 10%;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@tailwind base;
@tailwind components;
@tailwind utilities;